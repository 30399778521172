.about-page-sec {
  background-image: url("../img/about-sec-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 177px;
  margin-top: -136px;
}
.about-page-sec .about-card-wrapper {
  position: relative;
}
.about-page-sec .about-content-wrapper {
  position: absolute;
  top: 31%;
  left: 0;
  right: 0;
  text-align: center;
  width: 81%;
  margin: 0 auto;
}
.about-page-sec .about-card-wrapper {
  position: relative;
}
.about-page-sec .about-content-wrapper h3 {
  color: #fff;
  font-size: 60px;
  text-shadow: -6px 3px #1e1b1a;
}
.about-page-sec .about-content-wrapper p {
  color: #fff;
  font-size: 20px;
  text-shadow: -3px 1px black;
}
.about-sec .tanked-card-wrapper {
  text-align: center;
  position: relative;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper {
  position: absolute;
  top: 43%;
  left: 0;
  right: 0px;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
  font-size: 54px;
  color: #fff;
  text-shadow: -6px 3px #1e1b1a;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
  color: #ffff;
  text-shadow: -4px 1px #000000;
}
.about-sec .tanked-content-wrapper p.tanked-first-paragraph {
  font-size: 22px;
}
.about-card-wrapper {
  animation: swing ease-in-out 6s infinite alternate;
  transform-origin: center -20px;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.about-card-wrapper img {
  /* border: 5px solid #f8f8f8; */
  display: block;
  margin: 0px auto;
}
.about-card-wrapper:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  /* border: 1px solid #999; */
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.about-card-wrapper:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
}
@media (max-width: 1100px) {
  .about-page-sec .about-content-wrapper p {
    color: #fff;
    font-size: 17px;
  }
}
@media (max-width: 800px) {
  .ab .about-content-wrapper p {
    font-size: 14px;
  }

  .ab .about-content-wrapper h3 {
    font-size: 25px;
  }
}
@media (max-width: 480px) {
  .about-page-sec .about-card-wrapper {
    animation: none;
  }
  .ab .about-card-wrapper img {
    height: 467px;
    width: 100%;
  }
  .ab .about-content-wrapper p {
    color: #fff;
    font-size: 13px !important;
  }
  .ab .about-content-wrapper p {
    color: #fff;
    font-size: 13px !important;
    line-height: 1.3;
  }
  section.about-page-sec.ab .about-content-wrapper h3 {
    font-size: 18px !important;
  }
  .about-page-sec.ab .about-content-wrapper {
    width: 77%;
    top: 37%;
  }
}
@media (max-width: 414px) {
  .ab .about-card-wrapper img {
    height: 621px !important;
  }
  .ab .about-content-wrapper p {
    color: #fff;
    font-size: 14px !important;
    text-shadow: none !important;
  }
}
@media (max-width: 375px) {
  .ab .about-card-wrapper img {
    height: 506px;
  }
  .ab .about-content-wrapper p {
    color: #fff;
    font-size: 11.4px !important;
  }
}
@media only screen and (max-device-width: 926px) and (orientation: landscape) {
  .ab .about-content-wrapper p {
    color: #fff;
    font-size: 11px;
  }
}
