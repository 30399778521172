.merch-sec .merch-wrapper {
  background: #2b2624;
  padding: 14px;
  border: 5px solid #f6ae00;
  outline: 10px solid #2b2624;
  padding-top: 150px;
  margin-top: -150px;
}
.merch-sec {
  padding-top: 100px;
  background-image: url("../img/merch-bg.webp");
  background-attachment: fixed;
  background-size: cover;
  margin-top: -134px;
}
.merch-card-wrapper .merch-img-wrapper figure {
  margin: 0px;
}
.merch-card-wrapper .merch-price-wrapper {
  background: #070707d1;
  display: flex;
  justify-content: space-between;
  padding: 14px 5px;
  border: 3px solid #f6ae00;
  margin-top: -55px;
  position: relative;
  align-items: center;
}
.merch-card-wrapper .merch-price-wrapper h4 {
  margin: 0px;
  color: #fff;
  font-family: "Khand";
  font-size: 20px;
}
.merch-card-wrapper a:hover {
  text-decoration: none !important;
}
.merch-sec .merch-card-wrapper .new-pro {
  position: absolute;
  top: 0px;
  background: #f6ae00;
  padding: 6px 13px;
}
.merch-card-wrapper {
  position: relative;
  margin: 20px 0px;
}
.merch-sec .merch-card-wrapper .new-pro h5 {
  margin: 0px;
  color: #000000;
}
.merch-sec .merch-content-wrapper {
  text-align: center;
  position: relative;
  z-index: 2;
}
.merch-sec .merch-content-wrapper .merch-conetent-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.merch-sec .merch-content-wrapper .merch-conetent-wrapper h3 {
  font-size: 52px;
  color: #fff;
  text-shadow: -6px 3px #1e1b1a;
}
.merch-content-wrapper {
  animation: swing ease-in-out 6s infinite alternate;
  transform-origin: center -20px;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.merch-content-wrapper img {
  /* border: 5px solid #f8f8f8; */
  display: block;
  margin: 0px auto;
}
.merch-content-wrapper:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  /* border: 1px solid #999; */
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.merch-content-wrapper:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  /* background: #000; */
}
@media (max-width: 1280px) {
  .merch-content-wrapper img {
    height: 380px;
  }
}
@media (max-width: 800px) {
  .merch-card-wrapper .merch-img-wrapper img {
    width: 100%;
    object-fit: cover;
  }
  .merch-sec .merch-content-wrapper {
    z-index: 0;
  }
}
@media (max-width: 480px) {
  .merch-content-wrapper {
    animation: none;
  }
  .merch-content-wrapper img {
    height: 274px;
  }
  .merch-sec .merch-content-wrapper .merch-conetent-wrapper h3 {
    font-size: 31px;
    color: #fff;
    text-shadow: -3px 1px #1e1b1a;
  }
  .merch-sec .merch-wrapper {
    margin-top: -92px;
  }
  .merch-sec {
    margin-top: -157px;
  }
}
