@import url("https://fonts.googleapis.com/css2?family=Khand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700&display=swap");
@font-face {
	font-family: "Agency FB";
	src: url("../fonts//AGENCYB.TTF");
}
/* typography starts here */
.timer{
	font-size: 30px;
	color: #fff;
	text-align: center;
	margin: 0;
}
body {
	overflow-x: hidden;
	background-color: #000 !important;
}
h1 {
	font-family: "Agency FB";
	font-size: 80px;
	line-height: 1.5;
}
h2 {
	font-family: "Agency FB";
	font-size: 60px;
	line-height: 1.5;
}
h3 {
	font-family: "Agency FB";
	font-size: 54px;
	line-height: 1.5;
	text-transform: uppercase;
}
h4 {
	font-family: "Agency FB";
	font-size: 40px;
	line-height: 1.5;
}
h5 {
	font-family: "Agency FB";
	font-size: 25px;
	line-height: 1.5;
}
p {
	font-family: "Khand";
}
.hide-load{
	display: none;
}
.loader-container{
	text-align: center;
    margin-top: 22%;
}

/* typography ends here */
/* header starts here */
#header .header-wrapper ul.navbar-nav {
	flex-direction: row;
	justify-content: space-between;
}
#header {
	padding: 52px 0 20px 0;
	position: relative;
	z-index: 4;
	top: -28px;
}
#header .header-wrapper {
	background: #2b2624;
	padding: 12px 19px;
	outline: 10px solid #2b2624;
	border: 5px solid #f6ae00;
}
header .header-wrapper a {
	color: #fff;
	font-family: "Agency FB";
	font-size: 17px;
	font-weight: 700;
	text-shadow: -6px 3px #1e1b1a;
	text-decoration: none;
	text-transform: uppercase;
}
.active {
	color: #f6ae00;
}
header .mobile-header {
	width: 70%;
	height: 100%;
	position: fixed;
	background: #2b2624;
	top: 0;
	z-index: 3;
	right: 0;
	padding-top: 30px;
	transition: 0.7s;
	transform: translateX(110%);
	border: 4px solid #f6ae00;
	outline: 10px solid #2b2624;
}
header .mobile-header.show {
	transform: translateX(0%);
}
header .mobile-header ul.mobile-nav {
	margin-bottom: 0;
	padding: 0;
	list-style: none;
}
header .mobile-header .cancel {
	background: #fff;
	width: 35px;
	text-align: center;
	height: 35px;
	line-height: 32px;
	margin: 0px 20px 20px auto;
}
header .mobile-header ul.mobile-nav li.nav-item {
	padding: 10px 0;
	border: 0;
	border-bottom: 1px solid #dddddd4a;
}
header .mobile-header ul.mobile-nav li.nav-item a {
	color: #fff;
	padding: 0 20px;
	font-size: 20px;
	font-family: "Agency FB";
	text-transform: uppercase;
}
.canvas-icon.text-white {
	display: none;
}
header#header a.logo {
	display: none;
}
@media (max-width: 1366px) {
	header .header-wrapper a {
		text-shadow: -3px 2px #1e1b1a;
	}
	#header {
		top: -31px;
	}

	#header .canvas-icon i {
		font-size: 22px;
		background: #2b2824;
		padding: 6px;
		border: 2px solid #f2ab04;
		outline: 3px solid #2b2824;
		margin-top: 17px;
	}
}

@media (max-width: 800px) {
	#header .header-wrapper {
		display: none;
	}
	.canvas-icon.text-white {
		display: block;
	}
	header#header a.logo {
		display: block;
	}
	#header {
		top: -7px;
		padding-top: 0px;
	}
}
@media (max-width: 480px) {
	#header {
		top: -31px;
		padding: 33px 0px;
	}
}
@media (max-width: 414px) {
	header#header a.logo {
		transform: scale(1.2) translate(9px, 6px);
	}
}
/* header ends here */
/* banner starts here */
.banner-sec {
	background-image: url("../img/banner-bg.webp");
	background-size: cover;
	margin-top: -134px;
	position: relative;
	height: 120vh;
	overflow-x: hidden;
}
.header-wrapper a.active {
	color: #f6ae00;
}
.banner-card-wrapper h1 {
	font-size: 58px;
	font-weight: 700;
	color: #ffffff;
	text-shadow: -6px 3px #1e1b1a;
}
.banner-card-wrapper {
	position: relative;
	text-align: center;
	margin-top: 105px;
	z-index: 2;
}
.banner-card-wrapper .banner-content-wrapper {
	position: absolute;
	top: 39%;
	left: 0;
	right: 0px;
	z-index: 2;
}
.banner-sec .banner-card-wrapper::after {
	content: url("../img/drinks.webp");
	position: absolute;
	z-index: 0;
	left: 0px;
	right: 0;
	top: 114%;
	transform: scale(0.8);
}
.banner-sec .banner-left-img {
	position: absolute;
	left: -5%;
	top: 21%;
	z-index: 2;
}
.banner-sec .banner-img-wrapper img {
	height: 300px;
}
.banner-sec .banner-left-img {
	position: absolute;
	left: -9%;
	top: 28%;
}
.banner-sec .banner-left-img img {
	transform: scale(0.7);
}
.banner-sec .banner-right-img {
	position: absolute;
	right: -9%;
	top: 40%;
	z-index: 1;
}
.banner-sec .banner-right-img img {
	transform: scale(0.7);
}
.chair-img-wrapper img {
	height: 250px;
	z-index: 3;
}
.chair-img-wrapper img.chair-img-1 {
	position: absolute;
	left: 9%;
	bottom: 7%;
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
}
.chair-img-wrapper img.chair-img-2 {
	position: absolute;
	left: 28%;
	bottom: 7%;
	-webkit-animation: mover 2s infinite alternate;
	animation: mover 2s infinite alternate;
}
.chair-img-wrapper img.chair-img-3 {
	position: absolute;
	left: 44%;
	bottom: 12%;
	animation: mover 1s infinite alternate;
}
.chair-img-wrapper img.chair-img-4 {
	-webkit-animation: mover 2s infinite alternate;
	animation: mover 2s infinite alternate;
	bottom: 10%;
	left: 61%;
	position: absolute;
}
.chair-img-wrapper img.chair-img-5 {
	-webkit-animation: mover 1s infinite alternate;
	animation: mover 1s infinite alternate;
	bottom: 8%;
	position: absolute;
	right: 27px;
	z-index: 2;
}
.banner-sec .banner-img-wrapper::before {
	content: url("../img/tank-left-img.webp");
	position: absolute;
	left: -2%;
	top: 13%;
	z-index: -1;
	animation: moverleftright 1s infinite alternate;
	height: 312px;
	width: 280px;
}
.banner-sec .banner-img-wrapper::after {
	content: url("../img/tank-right-img.webp");
	position: absolute;
	right: 4%;
	top: 16%;
	z-index: -1;
	animation: moverleftright 1s infinite alternate;
	height: 374px;
	width: 252px;
}
.bubbles {
	position: absolute;
	top: 0%;
	right: 0%;
}
.bubbles2 {
	position: absolute;
	left: 0%;
	top: 39%;
}
.banner-and-bubbles-wrapper {
	position: relative;
}
@media (max-width: 1680px) {
	.banner-sec .banner-left-img {
		top: 35%;
	}
	.banner-sec .banner-right-img {
		top: 46%;
	}
	.banner-sec .banner-card-wrapper::after {
		top: 133%;
	}
	.chair-img-wrapper img.chair-img-1 {
		bottom: 0%;
	}
	.chair-img-wrapper img.chair-img-2 {
		bottom: 0%;
	}
	.chair-img-wrapper img.chair-img-3 {
		bottom: 0%;
	}
	.chair-img-wrapper img.chair-img-4 {
		bottom: 0%;
	}
	.chair-img-wrapper img.chair-img-5 {
		bottom: 0%;
	}
}
@media (max-width: 1600px) {
	.chair-img-wrapper img.chair-img-1 {
		left: 9%;
		bottom: 7%;
	}
	.chair-img-wrapper img.chair-img-2 {
		left: 28%;
		bottom: 7%;
		width: 243px;
		height: 267px;
		object-fit: contain;
	}
	.chair-img-wrapper img.chair-img-3 {
		left: 44%;
		bottom: 12%;
	}
	.chair-img-wrapper img.chair-img-4 {
		bottom: 10%;
		left: 61%;
	}
	.chair-img-wrapper img.chair-img-5 {
		bottom: 8%;
		position: absolute;
		right: 27px;
		z-index: 2;
	}
	.banner-sec .banner-left-img {
		left: -9%;
		top: 28%;
	}
	.banner-sec .banner-right-img {
		top: 40%;
	}
	.banner-sec .banner-card-wrapper::after {
		top: 114%;
	}
}
@media (max-width: 1440px) {
	.banner-sec .banner-left-img img {
		transform: scale(0.6);
	}
	.banner-sec .banner-left-img {
		left: -11%;
	}
	.banner-sec .banner-card-wrapper::after {
		left: 0px;
		right: 0;
		top: 100%;
		transform: scale(0.7);
	}
}
/* responsive starts here */
@media (max-width: 1440px) {
	.banner-sec {
		background-size: 100% 100%;
	}
	.chair-img-wrapper img {
		height: 194px;
	}
	.chair-img-wrapper img.chair-img-1 {
		bottom: 14%;
	}
	.chair-img-wrapper img.chair-img-2 {
		bottom: 10%;
		left: 29%;
	}
	.chair-img-wrapper img.chair-img-3 {
		left: 47%;
		bottom: 17%;
	}
	.chair-img-wrapper img.chair-img-4 {
		left: 62%;
		bottom: 16%;
	}
	.chair-img-wrapper img.chair-img-5 {
		bottom: 14%;
		right: 4%;
		z-index: 0;
	}
	.banner-sec .banner-right-img {
		right: -14%;
		top: 33%;
	}
	.banner-sec .banner-card-wrapper::after {
		top: 95%;
	}
	.banner-sec .banner-left-img {
		left: -13%;
		top: 24%;
	}
	.banner-sec .banner-img-wrapper img {
		height: 271px;
	}
	.banner-card-wrapper h1 {
		font-size: 50px;
	}
	.bubbles2 {
		left: -1%;
		top: 28%;
	}
	.bubbles {
		top: -11%;
		right: 0%;
	}
	.banner-sec .banner-img-wrapper::before {
		left: -4%;
		top: 6%;
		transform: scale(0.7);
		animation: none;
	}
	.banner-sec .banner-img-wrapper::after {
		right: 6%;
		top: 3%;
		animation: none;
		transform: scale(0.7);
	}
	.banner-sec {
		margin-top: -133px;
	}
}
@media (max-width: 1280px) {
	.banner-sec .banner-left-img {
		left: -13%;
		top: 22%;
	}
	.banner-sec .banner-right-img {
		right: -14%;
		top: 31%;
	}
	.bubbles2 {
		left: -3%;
		top: 28%;
	}
}
@media (max-width: 1100px) {
	.banner-sec .banner-img-wrapper::before {
		left: -18%;
		top: 6%;
	}
	.banner-sec .banner-img-wrapper::after {
		right: -25%;
		top: 16%;
	}
	.chair-img-wrapper img {
		height: 148px;
	}
	.banner-sec .banner-left-img {
		left: -16%;
		top: 21%;
	}
	.banner-sec .banner-right-img {
		right: -21%;
		top: 31%;
	}
	.bubbles2 {
		left: -6%;
		top: 28%;
	}
	.bubbles {
		top: -11%;
	}
}
@media (max-width: 800px) {
	.banner-card-wrapper {
		z-index: 0;
	}
	.chair-img-wrapper {
		display: none;
	}
	.banner-left-img {
		display: none;
	}
	.banner-right-img {
		display: none;
	}
	.bubbles2 {
		display: none;
	}
	.bubbles {
		display: none;
	}
	.banner-sec .banner-card-wrapper::after {
		display: none;
	}
	.banner-sec .banner-img-wrapper::before {
		left: 4%;
	}
	.banner-sec .banner-img-wrapper::after {
		right: 0%;
	}
	.banner-sec {
		background-image: url("../img/banner-mobile-bg.webp");
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.banner-sec .banner-img-wrapper img {
		height: 213px;
	}
	.banner-card-wrapper .banner-content-wrapper {
		top: 43%;
	}
	.banner-card-wrapper h1 {
		font-size: 35px;
	}
	.banner-sec .banner-img-wrapper::before {
		left: 11%;
		transform: scale(0.6);
		top: -6%;
	}
	.banner-sec .banner-img-wrapper::after {
		right: 5%;
		transform: scale(0.6);
		top: 0%;
	}
}
@media (max-width: 480px) {
	.banner-sec .banner-img-wrapper img {
		height: 143px !important;
	}
	.banner-sec {
		background-image: url("../img/banner-480-mobile.png");
		background-size: 100% 100%;
		background-position: center;
		background-repeat: no-repeat;
		margin-top: -137px !important;
		height: 433px !important;
	}
	.banner-card-wrapper {
		position: relative;
		text-align: center;
		margin-top: 20px;
	}
	.banner-sec .banner-img-wrapper::before {
		left: -18%;
		transform: scale(0.3);
		top: -43%;
	}
	.banner-sec .banner-img-wrapper::after {
		right: -6%;
		transform: scale(0.3);
		top: -57%;
	}
	.banner-card-wrapper h1 {
		font-size: 24px !important;
	}
	.banner-card-wrapper {
		position: relative;
		text-align: center;
		margin-top: 59px;
	}
}
@media (max-width: 414px) {
	.banner-sec {
		margin-top: -122px;
	}
}
/* banner ends here */
/* about css starts here */
.about-sec {
	background-image: url("../img/about-bg.webp");
	background-size: cover;
	background-repeat: no-repeat;
	padding-bottom: 200px;
	overflow-x: hidden;
}
.about-sec .tanked-card-wrapper {
	text-align: center;
	position: relative;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper {
	position: absolute;
	top: 38%;
	left: 0;
	right: 0px;
	width: 81%;
	margin: 0px auto;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
	font-size: 33px;
	color: #fff;
	text-shadow: -6px 3px #1e1b1a;
	line-height: 1;
}
.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
	color: #ffff;
	text-shadow: -4px 1px #000000;
}
.about-sec .tanked-content-wrapper p.tanked-first-paragraph {
	font-size: 22px;
}
.tanked-card-wrapper {
	animation: swing ease-in-out 6s infinite alternate;
	transform-origin: center -20px;
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.tanked-card-wrapper img {
	/* border: 5px solid #f8f8f8; */
	display: block;
	margin: 0px auto;
	height: 650px;
}
.tanked-card-wrapper:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: 1px solid #999; */
	top: -10px;
	left: 50%;
	z-index: 0;
	border-bottom: none;
	border-right: none;
	transform: rotate(45deg);
}
/* nail */
.tanked-card-wrapper:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: -14px;
	left: 54%;
	z-index: 5;
	border-radius: 50% 50%;
	background: #000;
}
.about-sec .bus-img {
	position: relative;
}
@media (max-width: 1440px) {
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 48px;
		color: #fff;
		text-shadow: -6px 3px #1e1b1a;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
		text-shadow: -3px 1px #000000;
	}
	.about-sec {
		background-image: url("../img/about-bg.webp") !important;
		background-size: cover !important;
		background-repeat: no-repeat;
		padding-bottom: 200px;
		overflow-x: hidden;
		background-color: #000;
	}
}
@media (max-width: 800px) {
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 33px;
	}
	.tanked-card-wrapper img {
		max-width: 100%;
	}
	.about-sec .tanked-content-wrapper p.tanked-first-paragraph {
		font-size: 17px;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper {
		width: 74%;
		margin: 0px auto;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper p br {
		display: none;
	}
}
@media (max-device-width: 480px) {
	.tanked-card-wrapper img {
		height: auto;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
		font-size: 11.4px;
		font-weight: 300 !important;
		text-shadow: none;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 30px;
		margin-bottom: 0px;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper {
		top: 31%;
	}
	.about-sec .tanked-card-wrapper {
		animation: none;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		text-shadow: none;
	}
	.about-sec .tanked-card-wrapper p {
		text-shadow: none !important;
	}
	.about-sec {
		padding-bottom: 50px;
	}
	.about-sec .bus_img_wrapper {
		overflow-x: hidden;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper {
		width: 83%;
	}
}
@media (max-width: 393px) {
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 26px;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
		font-size: 12.4px;
	}
}
@media (max-width: 414px) {
	.about-sec .tanked-card-wrapper .tanked-content-wrapper {
		top: 25%;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper p {
		margin-bottom: 0px;
	}
	.tanked-card-wrapper img {
		height: 758px;
	}
}
@media (max-width: 375px) {
	.tanked-card-wrapper img {
		height: 900px;
	}
}
/* about css ends here */
/* join sec starts here */
.join-sec {
	background-image: url("../img/join-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	position: relative;
	padding-bottom: 150px;
	padding-top: 150px;
}
.join-card-wrapper {
	position: relative;
}
.join-card-wrapper .join-content-wrapper {
	position: absolute;
	top: 35%;
	left: 0%;
	right: 0;
	text-align: center;
	width: 76%;
	margin: 0px auto;
}
.join-card-wrapper .join-content-wrapper h3 {
	font-size: 46px;
	color: #ffffff;
	text-shadow: -6px 3px #1e1b1a;
}
.join-card-wrapper .join-content-wrapper p {
	color: #fff;
	font-size: 17px;
	text-shadow: -5px 1px #000000;
}
.join-card-wrapper {
	animation: swing ease-in-out 6s infinite alternate;
	transform-origin: center -20px;
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.join-card-wrapper img {
	/* border: 5px solid #f8f8f8; */
	display: block;
	margin: 0px auto;
}
.join-card-wrapper:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: 1px solid #999; */
	top: -10px;
	left: 50%;
	z-index: 0;
	border-bottom: none;
	border-right: none;
	transform: rotate(45deg);
}
/* nail */
.join-card-wrapper:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: -14px;
	left: 54%;
	z-index: 5;
	border-radius: 50% 50%;
	background: #000;
}
@media (max-width: 1440px) {
	.join-card-wrapper .join-content-wrapper h3 {
		font-size: 50px;
		line-height: 1;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 15px;
		text-shadow: -3px 1px #000000;
	}
	.join-card-wrapper .join-content-wrapper {
		top: 39%;
	}
}
@media (max-width: 1366px) {
	.join-sec {
		/* background-size: 100% 100%; */
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 14px;
		text-shadow: -3px 1px #000000;
	}
}
@media (max-width: 1280px) {
	.join-card-wrapper .join-content-wrapper p {
		font-size: 14px;
	}
	.field-sec .field-card-wrapper img {
		max-width: 110%;
	}
	.join-card-wrapper img {
		height: 640px;
	}
}
@media (max-width: 1100px) {
	.join-card-wrapper .join-content-wrapper {
		width: 69%;
	}
	.join-card-wrapper .join-content-wrapper p br {
		display: none;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 14px;
		font-weight: 100;
	}
	.join-card-wrapper img {
		height: 724px;
	}
}
@media (max-width: 800px) {
	.join-card-wrapper .join-content-wrapper p {
		font-size: 18px;
		font-weight: 100;
	}
}
@media (max-width: 480px) {
	.join-sec {
		padding-bottom: 50px;
		padding-top: 50px;
	}
	.join-card-wrapper .join-content-wrapper h3 {
		font-size: 25px;
		text-shadow: -3px 1px #1e1b1a;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 11.5px !important;
		font-weight: 100;
		text-shadow: none;
	}
	.join-card-wrapper {
		animation: none;
	}
	.join-card-wrapper img {
		height: 675px;
	}
	.join-card-wrapper .join-content-wrapper {
		top: 37%;
	}
	.join-card-wrapper .join-content-wrapper h3 {
		font-size: 34px;
	}
}
@media (max-width: 414px) {
	.join-card-wrapper .join-content-wrapper {
		top: 37%;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 13px;
		font-weight: 100;
		line-height: 1.4;
		text-shadow: none;
	}
	.join-card-wrapper img {
		height: 609px;
	}
	.join-card-wrapper .join-content-wrapper {
		width: 77%;
	}
}
@media (max-width: 375px) {
	.join-card-wrapper .join-content-wrapper {
		top: 31%;
	}
	.join-card-wrapper img {
		height: 572px;
	}
}
/* join sec ends here */
/* drive sec starts here */
.drive-sec {
	background-image: url("../img/drive-bg.webp");
	background-repeat: no-repeat;
	background-size: 100% 100%;
	position: relative;
	padding-bottom: 618px;
	padding-top: 88px;
	overflow-x: hidden;
}
.drive-sec .drive-card-wrapper {
	position: relative;
}
.drive-sec .drive-content-wrapper {
	position: absolute;
	top: 40%;
	left: 0;
	right: 0;
	text-align: center;
	width: 81%;
	margin: 0 auto;
}
.drive-sec .drive-card-wrapper {
	position: relative;
}
.drive-sec .drive-content-wrapper h3 {
	color: #fff;
	font-size: 60px;
	text-shadow: -6px 3px #1e1b1a;
}
.drive-sec .drive-content-wrapper p {
	color: #fff;
	font-size: 18px;
	text-shadow: -3px 1px #000000;
}
.drive-card-wrapper {
	animation: swing ease-in-out 6s infinite alternate;
	transform-origin: center -20px;
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.drive-card-wrapper img {
	/* border: 5px solid #f8f8f8; */
	display: block;
	margin: 0px auto;
}
.drive-card-wrapper:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: 1px solid #999; */
	top: -10px;
	left: 50%;
	z-index: 0;
	border-bottom: none;
	border-right: none;
	transform: rotate(45deg);
}
/* nail */
.drive-card-wrapper:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: -14px;
	left: 54%;
	z-index: 5;
	border-radius: 50% 50%;
	background: #000;
}
@media (max-width: 1440px) {
	.drive-sec .drive-content-wrapper {
		top: 41%;
	}
}
@media (max-width: 1100px) {
	.drive-sec .drive-content-wrapper p br {
		display: none;
	}
	.drive-sec .drive-content-wrapper p {
		font-size: 16px;
	}
	.drive-sec {
		padding-bottom: 388px;
		padding-top: 88px;
	}
}
@media (max-width: 800px) {
	.drive-sec .drive-content-wrapper h3 {
		color: #fff;
		font-size: 35px;
		text-shadow: -6px 3px #1e1b1a;
	}
	.drive-sec .drive-content-wrapper p {
		font-size: 13px;
		margin-bottom: 0px;
	}
	.drive-sec .drive-content-wrapper {
		top: 38%;
	}
}
@media (max-width: 480px) {
	.drive-sec {
		padding-bottom: 0px;
		padding-top: 0px;
	}
	.drive-card-wrapper {
		animation: none;
	}
	.drive-card-wrapper img {
		height: 391px;
	}
	.drive-sec .drive-content-wrapper h3 {
		font-size: 29px;
		margin-bottom: 0px;
	}
	.drive-sec .drive-content-wrapper p {
		font-size: 11px;
		text-shadow: none;
		margin: 0px;
	}
}
@media (max-width: 414px) {
	.drive-card-wrapper img {
		height: 520px;
	}
	.drive-sec .drive-content-wrapper p {
		font-size: 12px;
		text-shadow: none;
		margin: 0px;
	}
}
@media (max-width: 375px) {
	.drive-card-wrapper img {
		height: 582px;
	}
}
/* drive sec ends here */
/* field-sec  starts here */
.field-sec {
	background-image: url("../img/field-bg.webp");
	background-repeat: no-repeat;
	background-size: cover;
	padding: 200px 0px;
	position: relative;
	overflow-x: hidden;
}
.field-sec .field-card-wrapper {
	position: relative;
	text-align: center;
}
.field-sec .field-content-wrapper {
	position: absolute;
	top: 34%;
	left: 0;
	right: 0;
	width: 70%;
	margin: 0px auto;
}
.field-sec .field-content-wrapper h3 {
	color: #fff;
	font-size: 47px;
	text-shadow: -6px 3px #1e1b1a;
}
.field-sec .field-content-wrapper p {
	color: #fff;
	font-size: 18px;
	text-shadow: -3px 1px #000000;
	font-family: "Khand";
	font-weight: 300;
}
.field-card-wrapper {
	animation: swing ease-in-out 6s infinite alternate;
	transform-origin: center -20px;
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.field-card-wrapper img {
	/* border: 5px solid #f8f8f8; */
	display: block;
	margin: 0px auto;
}
.field-card-wrapper:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: 1px solid #999; */
	top: -10px;
	left: 50%;
	z-index: 0;
	border-bottom: none;
	border-right: none;
	transform: rotate(45deg);
}
/* nail */
.field-card-wrapper:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: -14px;
	left: 54%;
	z-index: 5;
	border-radius: 50% 50%;
	background: #000;
}
@media (max-width: 1440px) {
	.field-sec .field-content-wrapper h3 {
		font-size: 42px;
	}
	.field-sec .field-content-wrapper p {
		color: #fff;
		font-size: 15px;
		text-shadow: -3px 1px #000000;
		word-spacing: 2px;
	}
}
@media (max-width: 1366px) {
	.field-sec .field-content-wrapper {
		top: 36%;
	}
	.field-sec .field-card-wrapper {
		left: -37px;
	}
}
@media (max-width: 1280px) {
	.field-sec .field-card-wrapper img {
		max-width: 110%;
	}
	.field-sec .field-content-wrapper {
		top: 42%;
		left: 9%;
		width: 80%;
	}
}
@media (max-width: 1100px) {
	.field-sec .field-card-wrapper img {
		max-width: 123%;
	}
	.field-sec .field-content-wrapper {
		top: 44%;
		left: 12%;
		width: 98%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 14px;
	}
}
@media (max-width: 800px) {
	.field-sec .field-card-wrapper img {
		max-width: 100%;
	}
	.field-sec .field-content-wrapper {
		top: 44%;
		left: 0%;
		width: 75%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 18px;
	}
	.field-sec .field-card-wrapper {
		left: 0;
	}
}
@media (max-width: 480px) {
	.field-sec .field-content-wrapper h3 {
		font-size: 22px;
		text-shadow: -2px 1px #2b2624;
	}
	.field-sec .field-content-wrapper p {
		font-size: 12px;
		text-shadow: none;
	}
	.field-sec .field-card-wrapper {
		animation: none;
	}
	.field-sec .field-card-wrapper img {
		height: 728px;
		max-width: 100%;
	}
	.field-sec {
		padding: 0px 0px;
	}
}
@media (max-width: 414px) {
	.field-sec .field-content-wrapper {
		top: 35%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 11.5px;
	}
	.field-sec .field-content-wrapper p {
		font-size: 14px;
		word-spacing: 0px;
		line-height: 1.3;
	}
	.field-sec .field-card-wrapper img {
		max-width: 100%;
		height: 781px;
	}
}
@media (max-width: 375px) {
	.field-sec .field-card-wrapper img {
		height: 709px;
	}
	.field-sec .field-content-wrapper {
		top: 41%;
	}
}
/* field-sec ends here */
/* roadmap starts here */
.roadmap {
	padding-top: 250px;
	padding-bottom: 64px;
	background-image: url("../img/roadmap-bg.webp");
	background-size: cover;
	background-repeat: no-repeat;
}
.roadmap .roadmap-wrapper h3 {
	text-align: center;
	font-size: 40px;
	margin: 0px;
	font-weight: 700;
	color: #fff;
	text-shadow: -6px 3px #1e1b1a;
}
.roadmap .roadmap-wrapper {
	background: #2b2624;
	padding: 28px 0px;
	mix-blend-mode: luminosity;
}
.roadmap .roadmap-bor {
	border: 5px solid #f6ae00;
	outline: 5px solid #2b2624;
}
.roadmap .phase-card-wrapper {
	position: relative;
}
.roadmap .phase-card-wrapper .phase-content-wrapper {
	position: absolute;
	top: 49%;
	left: 0;
	right: 0px;
	text-align: center;
	margin: 0px auto;
	width: 77%;
}
.roadmap .phase-card-wrapper .phase-content-wrapper p {
	color: #fff;
	text-shadow: -2px 1px #000000;
	font-size: 14px;
	font-weight: 300;
}
.roadmap .phase-card-wrapper .phase-content-wrapper h4 {
	color: #fff;
	font-family: "Agency FB";
	text-shadow: -4px 2px #1e1b1a;
	font-size: 20px;
}
.roadmap .phase2 {
	margin-top: 175px;
}
.roadmap .phase4 {
	margin-top: 175px;
}
.roadmap .phase1:after {
	content: url("../img/phase-arrow1.webp");
	position: absolute;
	right: -40%;
	top: 47%;
}
.roadmap .phase5:after {
	content: url("../img/phase-arrow1.webp");
	position: absolute !important;
	right: -35% !important;
	top: 33% !important;
}
.roadmap .phase2::before {
	content: url("../img/phase-arrow2.webp");
	position: absolute;
	right: -15%;
	top: -18%;
}
.roadmap .phase3::after {
	content: url("../img/phase-arrow3.webp");
	position: absolute;
	right: -35%;
	top: 26%;
}
.roadmap .phase6::after {
	position: absolute;
	right: -11% !important;
	top: -15% !important;
}
.roadmap .phase7::after {
	content: url("../img/phase-arrow3.webp");
	position: absolute;
	right: -38%;
	top: 33%;
}
.roadmap .phase4 img {
	height: 413px;
}
.roadmap .phase2 img {
	height: 369px;
}
.roadmap .phase5 img {
	height: 424px;
}
.roadmap .phase3 img {
	height: 466px;
}
.roadmap .phase7 img {
	height: 307px !important;
}
.roadmap .phase8 img {
	height: 317px !important;
}
.roadmap .phase8 {
	margin-top: 216px;
}
.roadmap .phase6 img {
	height: 400px !important;
}
.roadmap .phase4 {
	margin-top: 175px;
}
.roadmap .phase-card-wrapper.phase8 .phase-content-wrapper h4 {
	font-size: 18px !important;
}

.roadmap .phase-card-wrapper.phase8 .phase-content-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 39%;
}
.roadmap .phase-card-wrapper.phase1 .phase-content-wrapper {
	top: 45%;
}
.roadmap .phase-card-wrapper.phase2 .phase-content-wrapper {
	top: 42%;
}
.roadmap .phase-card-wrapper.phase3 .phase-content-wrapper {
	top: 39%;
}
.roadmap .phase-card-wrapper.phase4 .phase-content-wrapper {
	top: 39%;
}
@media (max-width: 1440px) {
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 13px;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper h4 {
		color: #fff;
		font-family: "Agency FB";
		text-shadow: -4px 2px #1e1b1a;
		font-size: 17px;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		position: absolute;
		top: 49%;
		left: 0;
		right: 0px;
		text-align: center;
		margin: 0px auto;
		width: 75%;
	}
	.roadmap .phase1:after {
		right: -43%;
		top: 49%;
	}
	.roadmap .phase2::before {
		right: -17%;
		top: -18%;
	}
	.roadmap .phase3::after {
		right: -37%;
		top: 26%;
	}
	.roadmap .phase6::before {
		right: -10%;
		top: -15%;
	}
	.roadmap .phase7::before {
		position: absolute;
		right: -44% !important;
		top: 33% !important;
	}
}
@media (max-width: 1366px) {
	.roadmap {
		background-size: 100% 100%;
	}
	.roadmap .phase-img-wrapper img {
		height: 287px;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		top: 48%;
		width: 79%;
	}
	.roadmap .phase1:after {
		right: -46%;
		top: 52%;
		transform: scale(0.9);
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper h4 {
		text-shadow: -3px 2px #1e1b1a;
		font-size: 18px !important;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		text-shadow: -1px 1px #000000;
	}
	.roadmap .phase2 .phase-content-wrapper {
		top: 49%;
		width: 79%;
	}
	.roadmap .phase2.phase-content-wrapper p {
		text-shadow: -2px 1px #000000;
	}
	.roadmap .phase2::before {
		right: -17%;
		top: -10%;
		transform: scale(0.9);
	}
	.roadmap .phase3::after {
		right: -45%;
		top: 33%;
		transform: scale(0.9);
	}
	.roadmap .phase5:after {
		right: -46% !important;
		top: 39% !important;
		transform: scale(0.9);
	}
	.roadmap .phase6::before {
		right: -14% !important;
		top: -11% !important;
	}
	.roadmap .phase7::after {
		right: -45%;
		top: 38%;
		transform: scale(0.9);
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 12px;
	}
	.roadmap .phase2 img {
		height: 429px !important;
	}
	.roadmap .phase3 img {
		height: 350px !important;
	}
	.roadmap .phase4 img {
		height: 351px !important;
	}
	.roadmap .phase5 img {
		height: 395px !important;
	}
	.roadmap .phase7 img {
		height: 346px !important;
	}
	.roadmap .phase-card-wrapper.phase3 .phase-content-wrapper {
		top: 38%;
	}
	.roadmap .phase-card-wrapper.phase4 .phase-content-wrapper {
		top: 42% !important;
	}
}
@media (max-width: 1280px) {
	.roadmap .phase-img-wrapper img {
		height: 287px;
		max-width: 111%;
	}
	.roadmap .col-lg-3 {
		padding: 0px;
	}
	.roadmap {
		overflow-x: hidden;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		top: 51%;
		width: 74%;
		left: 5%;
	}
	.roadmap .phase1:after {
		right: -46%;
		top: 54%;
		transform: scale(0.75);
	}
	.roadmap .phase2::before {
		right: -6%;
		top: -15%;
		transform: scale(0.75);
	}
	.roadmap .phase3::after {
		right: -47%;
		top: 54%;
		transform: scale(0.75);
	}
}
@media (max-width: 1100px) {
	.roadmap .phase-card-wrapper .phase-content-wrapper h4 {
		text-shadow: -3px 2px #1e1b1a;
		font-size: 19px;
	}
	.roadmap .phase1:after {
		right: -50%;
		top: 59%;
		transform: scale(0.5);
	}
	.roadmap .phase2::before {
		right: -14%;
		top: -9%;
		transform: scale(0.5);
	}
	.roadmap .phase3::after {
		right: -51%;
		top: 59%;
		transform: scale(0.5);
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		top: 51%;
		left: 12%;
	}
}
@media (max-width: 800px) {
	.roadmap .phase-card-wrapper {
		margin: 0px;
		text-align: center;
	}
	.roadmap {
		background-size: cover;
		padding: 50px 0px;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		left: 0px;
		width: 46%;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 18px;
	}
	.roadmap .phase-img-wrapper img {
		height: 395px;
		max-width: 100% !important;
		width: 89%;
	}
}
@media (max-width: 480px) {
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 13px !important;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		left: 0px !important;
		width: 72% !important;
	}
	.roadmap .phase2::before {
		display: none;
	}
	.roadmap .phase3::after {
		display: none;
	}
	.roadmap .phase4::before {
		display: none;
	}
	.roadmap {
		padding-top: 98px;
		padding-bottom: 0px;
		background-position: top center;
	}
}
@media (max-width: 375px) {
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 13px;
	}
}
/* roadmap ends here */
/* animated-sec starts here */
.animated-sec {
	padding-top: 0px;
	/* padding-bottom: 80px; */
	background: #000;
}
.animated-sec .video-sec-wrapper {
	background: #2b2624;
	padding: 23px;
	border: 5px solid #f6ae00;
	outline: 10px solid #2b2624;
	position: relative;
	margin-top: -134px;
	z-index: 1;
}
.animated-sec .video-sec-wrapper figure {
	margin: 0;
}
.video-sec-wrapper img.video-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}
.animated-sec .video-content-wrapper {
	text-align: center;
	position: relative;
	z-index: 2;
}
.animated-sec .video-content-wrapper .video-conetent-wrapper {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
}
.animated-sec .video-content-wrapper .video-conetent-wrapper h3 {
	font-size: 67px;
	color: #fff;
	text-shadow: -6px 3px #1e1b1a;
}
@media (max-width: 1366px) {
	.animated-sec {
		padding-top: 0px;
	}
	.animated-sec .video-heading-img-wraper img {
		height: 315px;
	}
	.animated-sec .video-content-wrapper .video-conetent-wrapper h3 {
		font-size: 49px;
	}
}
@media (max-width: 800px) {
	.animated-sec .video-heading-img-wraper img {
		height: 277px;
	}
	.animated-sec .video-content-wrapper .video-conetent-wrapper h3 {
		font-size: 38px;
	}
	.animated-sec .video-sec-wrapper {
		margin-top: -87px;
	}
}
@media (max-width: 480px) {
	.animated-sec .video-heading-img-wraper img {
		height: 178px;
	}
	.animated-sec .video-content-wrapper .video-conetent-wrapper h3 {
		font-size: 26px;
		text-shadow: -3px 2px #1e1b1a;
	}
	.animated-sec .video-sec-wrapper {
		margin-top: -59px;
		padding: 10px;
	}
}
@media (max-width: 414px) {
	.video-sec-wrapper img.video-btn {
		transform: translate(-50%, -50%) scale(0.5);
	}
}
/* animated-sec ends here */
/* footer starts here */
#footer .footer-wrapper ul.navbar-nav {
	flex-direction: row;
	justify-content: space-between;
}
#footer {
	padding-top: 75px;
	position: relative;
	z-index: 1;
	padding-bottom: 90px;
}
#footer .footer-wrapper {
	background: #2b2624;
	padding: 22px;
	outline: 10px solid #2b2624;
	border: 5px solid #f6ae00;
}
footer .footer-wrapper a {
	color: #fff;
	font-family: "Agency FB";
	font-size: 20px;
	font-weight: 700;
	text-shadow: -6px 3px #1e1b1a;
	text-decoration: none;
	text-transform: uppercase;
}
.footer-wrapper a.active {
	color: #f6ae00;
}
.footer-card-wrapper {
	position: relative;
}
.footer-card-wrapper .footer-content-wrapper {
	position: absolute;
	top: 33%;
	left: 0;
	right: 0;
	text-align: center;
}
.footer-card-wrapper .footer-content-wrapper ul {
	padding: 0px;
	list-style: none;
	display: flex;
	justify-content: center;
}
.footer-card-wrapper .footer-content-wrapper ul li {
	margin: 24px 10px;
}
.footer-card-wrapper .footer-content-wrapper h3 {
	color: #fff;
	font-size: 50px;
	font-family: "Agency FB";
	text-shadow: -6px 3px #1e1b1a;
}
#footer .footer-btn-wrapper button.btn:hover {
	box-shadow: -6px 7px 0px 0px #fff;
}
#footer .footer-btn-wrapper button.btn {
	background: #000;
	border-radius: 0px;
	margin: 0px 10px;
	border: 4px solid #f6ae00;
	width: 250px;
	height: 75px;
	box-shadow: none;
	color: #fff;
	font-family: "Poppins";
	font-size: 20px;
	font-weight: 700;
	transition: all 0.4s ease-in-out;
}
#footer .footer-btn-wrapper button.btn span {
	margin-left: 20px;
}
.footer-card-wrapper .footer-content-wrapper ul li a img:hover {
	transform: scale(1.1);
}
.footer-card-wrapper .footer-content-wrapper ul li a img {
	transition: all 0.3s ease-in-out;
}
/* modal css starts here */
div#exampleModal .modal-content {
	background: url("../img/phase4_mobile.png");
	border: 0px;
	background-repeat: no-repeat;
	background-position: center;
	height: 582px;
	background-size: 100% 100%;
}
div#exampleModal .modal-dialog {
	max-width: 40%;
	margin: 144px auto;
}
div#exampleModal .email-heading-wrapper {
	width: 469px;
	position: absolute;
	margin: 0 auto;
	top: 60%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
}
.enter-email h2 {
	color: #fff;
	font-size: 50px;
	font-family: "Agency FB";
	text-shadow: -6px 3px #1e1b1a;
}
.enter-email p {
	color: #fff;
	font-size: 16px;
	font-family: "Agency FB";
	text-shadow: -6px 3px #1e1b1a;
}

div#exampleModal .enter-email input {
	background: #000000;
	padding: 10px;
	border: 3px solid #f6ae00;
	align-items: center;
	height: 50px;
	font-family: "Agency FB";
	color: #fff;
}
div#exampleModal .enter-email input::placeholder {
	color: #fff;
	font-family: "Agency FB";
}
div#exampleModal .enter-email button.btn {
	background: #000000;
	padding: 10px;
	border: 3px solid #f6ae00;
	align-items: center;
	height: 50px;
	font-family: "Agency FB";
	color: #fff;
}
/* modal css ends here */
/* responsive starts here */
@media (max-width: 1366px) {
	footer .footer-wrapper a {
		text-shadow: -3px 2px #1e1b1a;
	}
}
@media (max-width: 1100px) {
	.footer-card-wrapper .footer-content-wrapper h3 {
		font-size: 42px;
	}
	#footer .footer-btn-wrapper button.btn {
		height: 66px;
	}
}
@media (max-width: 800px) {
	.footer-card-wrapper .footer-content-wrapper h3 {
		font-size: 26px;
	}
	.footer-card-wrapper .footer-content-wrapper ul li a img {
		height: 30px;
	}
	footer .footer-wrapper a {
		font-size: 14px;
	}
}
@media (max-width: 480px) {
	.footer-card-wrapper .footer-content-wrapper {
		top: 40%;
	}
	.footer-card-wrapper .footer-content-wrapper h3 {
		font-size: 18px;
		text-shadow: none;
	}
	#footer .footer-btn-wrapper button.btn img {
		height: 19px;
	}
	#footer .footer-btn-wrapper button.btn {
		height: 47px !important;
		width: unset !important;
		font-size: 10px !important;
	}
	.footer-card-wrapper .footer-img-wrapper img {
		/* height: 359px !important; */
	}
	#footer .footer-wrapper ul.navbar-nav li a {
		font-size: 17px;
		line-height: 2;
	}
	#footer .footer-btn-wrapper button.btn span {
		margin-left: 10px;
	}
	#footer .footer-wrapper ul.navbar-nav {
		display: block;
		column-count: 2;
	}
}
/* footer ends here */
@keyframes mover {
	0% {
		transform: translateY(0);
	}
	100% {
		transform: translateY(-10px);
	}
}

@keyframes moverleftright {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(-10px);
	}
}
@keyframes swing {
	0% {
		transform: rotate(3deg);
	}
	100% {
		transform: rotate(-3deg);
	}
}
@media (max-width: 390px) {
	.field-sec .field-content-wrapper {
		top: 32%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 13.5px;
	}
}
/* @media only screen and (max-device-width: 480px) and (max-device-height: 926px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)  */
@media only screen and (max-device-width: 926px) and (orientation: landscape) {
	.banner-sec .banner-img-wrapper::before {
		left: -16%;
		top: -43%;
		transform: scale(0.4);
	}
	.join-card-wrapper img {
		/* border: 5px solid #f8f8f8; */
		display: block;
		margin: 0px auto;
		width: 90%;
	}
	.banner-sec {
		margin-top: -158px;
	}
	.banner-sec .banner-img-wrapper::after {
		right: -4%;
		top: -57%;
		transform: scale(0.4);
	}
	.banner-sec .banner-right-img {
		right: -36%;
		top: 25%;
		z-index: 0;
	}
	.field-sec .field-card-wrapper {
		left: 0px;
		animation: none;
	}
	.banner-sec .banner-left-img {
		left: -25%;
		top: 11%;
	}
	.bubbles {
		display: none;
	}
	.bubbles2 {
		display: none;
	}
	.banner-sec .banner-img-wrapper img {
		height: 217px;
	}
	.banner-card-wrapper h1 {
		font-size: 38px;
	}
	.banner-sec .banner-card-wrapper::after {
		display: none;
	}
	.chair-img-wrapper img {
		height: 111px;
	}
	.tanked-card-wrapper img {
		width: 100%;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 33px;
	}
	.about-sec .tanked-content-wrapper p.tanked-first-paragraph {
		font-size: 16px;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 15px;
		font-weight: 100;
	}
	.drive-card-wrapper img {
		height: 692px;
		width: 100%;
	}
	.drive-sec .drive-content-wrapper p {
		font-size: 16px;
		margin: 0px;
	}
	.field-sec .field-content-wrapper {
		top: 41%;
		left: 0%;
		width: 84%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 18px;
	}
	.roadmap .phase-img-wrapper img {
		height: unset !important;
		max-width: 100%;
		margin: 0px auto;
		display: flex;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper {
		top: 51%;
		width: 49%;
		left: 0%;
	}
	.roadmap .phase2 {
		margin-top: 0px;
	}
	.roadmap .phase4 {
		margin-top: 0px;
	}
	footer#footer .footer-img-wrapper img {
		height: 608px;
		width: 100%;
	}
	.banner-sec {
		background-size: cover;
		margin-top: -134px;
		position: relative;
		height: 133vh;
		overflow-x: hidden;
	}
	.field-sec .field-card-wrapper img {
		max-width: 100%;
		width: 100%;
	}
}
@media only screen and (max-device-width: 734px) and (orientation: landscape) {
	.about-sec .tanked-content-wrapper p.tanked-first-paragraph {
		font-size: 14px;
		line-height: 1.3;
		margin-bottom: 5px;
	}
	.about-sec .tanked-content-wrapper p {
		font-size: 14px !important;
		line-height: 1.3;
	}
	.about-sec .tanked-card-wrapper .tanked-content-wrapper h3 {
		font-size: 28px;
		line-height: 1;
	}
	.join-card-wrapper .join-content-wrapper p {
		font-size: 16px;
		font-weight: 100;
	}
	.drive-card-wrapper img {
		height: unset;
		width: 100%;
	}
	.field-sec .field-content-wrapper p {
		font-size: 16px;
		line-height: 1.3;
	}
	.roadmap .phase-card-wrapper .phase-content-wrapper p {
		font-size: 15px;
		line-height: 1.3;
	}
	#footer .footer-btn-wrapper button.btn {
		background: #000;
		border-radius: 0px;
		margin: 0px 10px;
		border: 4px solid #f6ae00;
		width: 188px;
		height: 53px;
		box-shadow: none;
		color: #fff;
		font-family: "Poppins";
		font-size: 14px;
		font-weight: 700;
		transition: all 0.4s ease-in-out;
	}
	#footer .footer-btn-wrapper button.btn img {
		width: 17%;
	}
	.footer-img-wrapper img.img-fluid {
		height: 500px;
	}
	.ab .about-content-wrapper p {
		color: #fff;
		font-size: 13px;
		line-height: 1.3;
	}
	.ab .about-content-wrapper h3 {
		font-size: 25px;
		line-height: 1;
	}
	.ab .about-card-wrapper img {
		height: 500px;
	}
	.sec-about .about-content-wrapper h3 {
		font-size: 31px;
	}
	.sec-about .about-img-wrapper img {
		/* border: 5px solid #f8f8f8; */
		display: block;
		margin: 0px auto;
		height: unset;
		width: 100%;
	}
	.about-card-wrapper .newsletter-wrapper {
		width: 100%;
		margin: 0px auto;
		margin-top: 40px;
	}
}
@media only screen and (max-device-width: 667px) and (orientation: landscape) {
	.banner-sec {
		height: 148vh;
	}
	.join-card-wrapper .join-content-wrapper h3 {
		font-size: 31px;
	}
	.field-sec .field-content-wrapper h3 {
		font-size: 26px;
	}
	.field-sec .field-content-wrapper {
		top: 32%;
	}
	.roadmap .phase2::before {
		display: none;
	}
	.roadmap .phase-card-wrapper.phase8 .phase-content-wrapper {
		top: 49% !important;
	}
}
