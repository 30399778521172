.schoolies-sec {
	background-image: url("../img/schoolies-wrapper.png");
	padding-top: 100px;
	margin-top: -114px;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}
.schoolies-sec .schoolies-wrapper {
	background: #2b2624;
	border: 4px solid #f6ae00;
	outline: 7px solid #2b2624;
	padding: 177px 79px 100px 79px;
	margin-top: -152px;
}
.schoolies-card-wrapper {
	position: relative;
	border: 3px solid #f6ae00;
	margin: 22px 0px;
}
.schoolies-card-wrapper .schoolies-content-wrapper {
	position: absolute;
	top: 0px;
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	padding: 17px;
}
.schoolies-card-wrapper img.schoolies-img {
	filter: brightness(0.5);
	height: 328px;
	object-fit: cover;
}
.comment-and-heart-wrapper ul {
	display: flex;
	padding: 0px;
	list-style: none;
	margin: 0;
}
.comment-and-heart-wrapper ul li i.fa.fa-heart {
	color: #d7443e;
}
.comment-and-heart-wrapper {
	display: flex;
	justify-content: space-between;
	margin-bottom: 14px;
}
.schoolies-content-wrapper .school-name-wrapper h4 {
	/* border-bottom: 1px solid #ffffff;	 */
	font-family: "Agency FB";
	font-size: 30px;
	line-height: 1.5;
	color: #fff;
}
.schoolies-content-wrapper h5 {
	font-family: "Khand";
	font-size: 20px;
	color: #fff;
}
.comment-and-heart-wrapper ul li {
	margin: 0px 10px 0px 0px;
	color: #fff;
	font-family: "Khand";
	font-size: 16px;
}
.comment-and-heart-wrapper ul li i {
	color: #fff;
}
.schoolies-card-wrapper figure {
	margin: 0px;
}
.schoolies-card-wrapper img.crown {
	height: 15px;
}
.schoolies-sec .schoolies-sec-content-wrapper {
	text-align: center;
	position: relative;
	z-index: 2;
}
.schoolies-sec .schoolies-sec-content-wrapper .schoolies-sec-conetent-wrapper {
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
}
.schoolies-sec
	.schoolies-sec-content-wrapper
	.schoolies-sec-conetent-wrapper
	h3 {
	font-size: 52px;
	color: #fff;
	text-shadow: -6px 3px #1e1b1a;
}
.schoolies-wrapper .post-wrapper .heading-wrapper {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
}
.schoolies-wrapper .post-wrapper .heading-wrapper h5 {
	color: #fff;
	font-size: 20px;
	margin: 0px 10px;
}
.schoolies-wrapper .post-wrapper img.admin_img {
	height: 44px;
	width: 44px;
	border-radius: 50%;
}
.schoolies-wrapper .des-wrapper p {
	color: #fff;
	font-size: 17px;
	line-height: 1.6;
	font-weight: 300;
}
.schoolies-wrapper .social-icon-wrapper ul {
	padding: 18px 0;
	list-style: none;
	display: flex;
	margin-top: 30px;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
}
.schoolies-wrapper .social-icon-wrapper ul li i {
	color: #fff;
	margin: 0px 15px 0px 0px;
}
.schoolies-wrapper .social-icon-wrapper ul {
	padding: 18px 0;
	list-style: none;
	display: flex;
	margin-top: 30px;
	border-top: 1px solid #707070;
	border-bottom: 1px solid #707070;
}
.schoolies-wrapper .social-icon-wrapper ul li i {
	color: #fff;
	margin: 0px 15px 0px 0px;
}
.com-and-heart-wrapper ul {
	padding: 0;
	margin: 0px;
	list-style: none;
	display: flex;
}
.com-and-heart-wrapper {
	display: flex;
	justify-content: space-between;
}
.com-and-heart-wrapper ul li {
	color: #fff;
	margin: 0 10px 0 0;
	font-family: "Khand";
	font-size: 16px;
}
.com-and-heart-wrapper ul.heart li i {
	color: #d7443e;
}
.schoolies-wrapper .upload-comment {
	margin-top: 40px;
}
.schoolies-wrapper .upload-comment label {
	color: #fff;
	font-family: "Agency FB";
	font-size: 30px;
}
.schoolies-wrapper .upload-comment .input-group {
	background: #000;
	padding: 10px;
	border: 4px solid #f6ae00;
}
.schoolies-wrapper .upload-comment .input-group input {
	background: transparent;
	border: 0px;
	font-family: "Poppins";
	height: 40px;
}
.schoolies-wrapper .upload-comment .input-group input::placeholder {
	color: #ffffff;
	font-family: "Poppins";
	font-size: 16px;
}
.schoolies-wrapper .upload-comment .input-group input:focus {
	outline: none;
	box-shadow: none;
}
.schoolies-wrapper .upload-comment .input-group button {
	border: 3px solid #f6ae00;
	border-radius: 0px;
	padding: 4px 39px;
	height: 43px;
	color: #fff;
	font-family: "Poppins";
	font-size: 14px;
	font-weight: 700;
}
.schoolies-wrapper .upload-comment .input-group button img {
	margin-right: 6px;
}
.schoolies-wrapper .recent-post-wrapper {
	position: relative;
}
.schoolies-wrapper .recent-post-wrapper .recent-content-wrapper {
	position: absolute;
	bottom: 0px;
	width: 100%;
	padding: 20px 20px 10px 20px;
	background: #000000b8;
	border: 2px solid #f6ae00;
}
.schoolies-wrapper .recent-post-wrapper .recent-content-wrapper h4 {
	color: #fff;
	font-size: 30px;
	border-bottom: 1px solid;
	padding-bottom: 16px;
}
.schoolies-wrapper .recents-post-wrapper {
	padding-top: 95px;
}
.schoolies-wrapper h4.res {
	color: #fff;
	font-size: 30px;
	margin-bottom: 20px;
}
.schoolies-sec-content-wrapper {
	animation: swing ease-in-out 6s infinite alternate;
	transform-origin: center -20px;
	/* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.schoolies-sec-content-wrapper img {
	/* border: 5px solid #f8f8f8; */
	display: block;
	margin: 0px auto;
}
.schoolies-sec-content-wrapper:after {
	content: "";
	position: absolute;
	width: 20px;
	height: 20px;
	/* border: 1px solid #999; */
	top: -10px;
	left: 50%;
	z-index: 0;
	border-bottom: none;
	border-right: none;
	transform: rotate(45deg);
}
/* nail */
.schoolies-sec-content-wrapper:before {
	content: "";
	position: absolute;
	width: 5px;
	height: 5px;
	top: -14px;
	left: 54%;
	z-index: 5;
	border-radius: 50% 50%;
	/* background: #000; */
}
.post-wrapper h4 {
	font-size: 40px;
	font-family: "Agency FB";
	color: #fff;
	margin: 0px;
	margin-left: 21px;
}
.post-wrapper .main-heading-wrapper {
	/* margin-bottom: 30px; */
}
.schoolies-sec .schoolies-wrapper .react-player video {
	height: 300px !important;
	width: 100%;
	object-fit: cover;
}
.schoolies-sec .schoolies-wrapper .react-player__preview {
	height: 328px !important;
	filter: brightness(0.5);
}
.schoolies-posts .schoolies-wrapper .react-player video {
	height: 100% !important;
	width: 100% !important;
}

.schoolies-sec .cursor_pointer{
	cursor: pointer;
}

.schoolies-sec .cursor_pointer:hover{
	text-decoration: underline;
}

@media (max-width: 1280px) {
	.schoolies-sec-content-wrapper img {
		height: 400px;
	}
	.schoolies-sec
		.schoolies-sec-content-wrapper
		.schoolies-sec-conetent-wrapper
		h3 {
		font-size: 47px;
	}
	.schoolies-sec
		.schoolies-sec-content-wrapper
		.schoolies-sec-conetent-wrapper {
		top: 54%;
	}
}
@media (max-width: 1100px) {
	.schoolies-wrapper .recent-post-wrapper .recent-content-wrapper h4 {
		font-size: 22px;
	}
}
@media (max-width: 480px) {
	.schoolies-sec .schoolies-wrapper .react-player video {
		object-fit: contain;
	}
	.post-wrapper h4 {
		font-size: 38px;
		font-family: "Agency FB";
		color: #fff;
	}
	.schoolies-sec .schoolies-wrapper {
		background: #2b2624;
		border: 4px solid #f6ae00;
		outline: 7px solid #2b2624;
		padding: 137px 30px 52px 30px;
		margin-top: -152px;
	}
	.schoolies-sec .schoolies-sec-content-wrapper {
		animation: none;
		z-index: 0;
	}

	.schoolies-sec .schoolies-sec-content-wrapper img {
		height: 300px;
	}
	.schoolies-sec
		.schoolies-sec-content-wrapper
		.schoolies-sec-conetent-wrapper
		h3 {
		font-size: 32px;
	}
	.schoolies-sec {
		margin-top: -161px;
	}
}
@media (max-width: 375px) {
	.post-wrapper h4 {
		font-size: 34px;
		font-family: "Agency FB";
		color: #fff;
	}
}
/* @media only screen and (max-device-width: 480px) and (max-device-height: 926px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: landscape)  */
@media only screen and (max-device-width: 667px) and (orientation: landscape) {
	.schoolies-sec .schoolies-wrapper {
		background: #2b2624;
		border: 4px solid #f6ae00;
		outline: 7px solid #2b2624;
		padding: 177px 23px 100px 23px;
		margin-top: -152px;
	}
}
