.product_page {
  background: url("../img/merch-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 200px;
  padding-bottom: 50px;
  margin-top: -231px;
}
.product_page .product-wrapper {
  background: #2b2624;
  padding-top: 185px;
  border: 4px solid #f6ae00;
  outline: 9px solid #2b2624;
  padding-bottom: 20px;
  margin-top: -135px;
}
.product-wrapper .pro-slider-img img {
  width: 130px;
  height: 100px;
  object-fit: cover;
  margin: 0px 12px 0px 0px;
  border: 4px solid #f6ae00;
}
.product_page .product-main-img img {
  outline: 4px solid #f6ae00;
}
.product_page .product-content-wrapper h3 {
  font-family: "Agency FB";
  color: #fff;
  font-size: 50px;
}
.product_page .product-content-wrapper h5 {
  color: #f6ae00;
  font-size: 18px;
  font-family: "Khand";
  margin: 20px 0px;
}
.product_page .product-content-wrapper h4 {
  color: #f6ae00;
  font-size: 26px;
  margin: 17px 0px;
  font-family: "Agency FB";
}
.product_page .product-content-wrapper h6 {
  font-size: 18px;
  font-family: "Agency FB";
  color: #fff;
}
.product_page .product-content-wrapper p {
  font-family: "Khand";
  font-size: 16px;
  color: #fff;
  margin-top: 15px;
}
.product-wrapper .product-quality-btn {
  display: flex;
  align-items: flex-end;
  margin: 22px 0px;
}
.product-wrapper .product-quality-btn .counter-wrapper {
  flex: 0 0 33%;
}
.product-wrapper .product-quality-btn .btn-wrapper {
  flex: 0 0 67%;
}
.product-quality-btn .counter-btn-wrapper {
  display: flex;
  align-items: center;
}
.product-quality-btn .counter-btn-wrapper input {
  width: 54px;
  text-align: center;
  height: 40px;
  border: 3px solid #f6ae00;
  background: #000000;
  color: #fff;
  font-size: 25px;
  font-family: "Khand";
  line-height: 0;
}
.product-quality-btn .counter-btn-wrapper button {
  height: 40px;
  width: 36px;
  background: #000000;
  color: #fff;
  font-size: 26px;
  font-family: "Agency FB";
  border: 2px solid #f6ae00;
  line-height: 0;
}

.product-wrapper .counter-wrapper label {
  font-family: "Khand";
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.product_page .btn-wrapper button.btn {
  background: black;
  color: #fff;
  font-family: "Poppins";
  font-size: 20px;
  font-weight: 700;
  border-radius: 0px;
  border: 3px solid #f6ae00;
}
.product_page .tabs-wrapper {
  border-top: 5px solid #f6ae00;
  border-bottom: 5px solid #f6ae00;
  margin-top: 65px;
}
.product_page .tabs-wrapper ul {
  border: 0px;
}
.product-wrapper .tabs-wrapper .nav-tabs .nav-link.active {
  background: #f6ae00;
  border: 0px;
  border-radius: 0px;
  color: #000000;
  font-size: 30px;
  font-family: "Agency FB";
  font-weight: 700;
  /* width: 198px; */
  text-align: center;
}

.product-wrapper .tabs-wrapper .nav-tabs .nav-link {
  background: transparent;
  border: 0px;
  border-radius: 0px;
  color: #ffffff;
  font-size: 30px;
  font-family: "Agency FB";
  font-weight: 700;
  padding: 10px 20px;
  text-align: center;
}
.product-wrapper .tab-content-wrapper p {
  color: #fff;
  font-family: "Khand";
  padding: 0px 23px;
  padding-top: 18px;
}
.product-wrapper .tab-content-wrapper {
  margin-top: 30px;
}
.product_page .product_page-content-wrapper {
  text-align: center;
  position: relative;
  z-index: 2;
}
.product_page .product_page-content-wrapper .product_page-conetent-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.product_page .product_page-content-wrapper .product_page-conetent-wrapper h3 {
  font-size: 52px;
  color: #fff;
  text-shadow: -6px 3px #1e1b1a;
}
/* modal css starts here */
.cart_modal .counter-btn-wrapper {
  display: flex;
  align-items: center;
}
.cart_modal .counter-btn-wrapper input {
  width: 54px;
  text-align: center;
  height: 40px;
  border: 3px solid #f6ae00;
  background: #000000;
  color: #fff;
  font-size: 25px;
  font-family: "Khand";
  line-height: 0;
}
.cart_modal .counter-btn-wrapper button {
  height: 40px;
  width: 36px;
  background: #000000;
  color: #fff;
  font-size: 26px;
  font-family: "Agency FB";
  border: 2px solid #f6ae00;
  line-height: 0;
}

.product-wrapper .counter-wrapper label {
  font-family: "Khand";
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.cart_modal .modal-dialog {
  margin-right: 0px;
  max-width: 400px;
}
.cart_modal .modal-content {
  background: #2b2624;
  border-radius: 0px;
  border: 4px solid #f6ae00;
  outline: 8px solid #2b2624;
}
.cart-sidebar-wrapper .cart-content-wrapper {
  margin: 20px 0px;
}
.cart-sidebar-wrapper .cart-content-wrapper h4 {
  color: #fff;
  font-size: 40px;
  font-family: "Agency FB";
}
.cart_modal .cart-pro-wrapper {
  margin: 26px 0px;
  height: 500px;
  overflow-y: scroll;
}
.cart_modal .cart-pro-wrapper::-webkit-scrollbar {
  display: none;
}
.cart-sidebar-wrapper .product-wrapper {
  display: flex;
  align-items: center;
}
.cart-sidebar-wrapper .product-wrapper h5 {
  color: #ffffff;
  font-size: 25px;
  font-weight: 700;
}

.cart-sidebar-wrapper .product-wrapper h6 {
  font-size: 30px;
  color: #f6ae00;
  font-family: "Agency FB";
}
.cart-sidebar-wrapper .product-wrapper .product-img-wrapper figure {
  margin: 0px;
}
.cart-sidebar-wrapper .product-wrapper .product-img-wrapper img {
  height: 133px;
  width: 145px;
  margin-right: 28px;
  object-fit: cover;
}
.total-price-wrapper button {
  background: #000;
  width: 100%;
  padding: 9px;
  font-family: "Poppins";
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border: 3px solid #f6ae00;
  margin-top: 28px;
}
.total-price-wrapper button img {
  margin-right: 20px;
}
.cart-sidebar-wrapper .total-price-wrapper h3 {
  color: #ffff;
  font-size: 40px;
}
.cart-sidebar-wrapper .total-price-wrapper h4 {
  color: #f6ae00;
  font-size: 50px;
}
/* modal css ends here */
@media (max-width: 1100px) {
  .product-wrapper .pro-slider-img img {
    width: 128px;
  }
  .product_page .product_page-heading-img-wraper img {
    height: 335px;
  }
  .product_page
    .product_page-content-wrapper
    .product_page-conetent-wrapper
    h3 {
    font-size: 43px;
  }
}
@media (max-width: 800px) {
  .product_page .product_page-content-wrapper {
    z-index: 0;
  }
}
@media (max-width: 480px) {
  .product-wrapper .pro-slider-img img {
    width: 111px;
  }
  .product-wrapper .tabs-wrapper .nav-tabs .nav-link.active {
    font-size: 17px;
  }
  .product-wrapper .tabs-wrapper .nav-tabs .nav-link {
    font-size: 17px;
  }
  .product-content-wrapper {
    margin-top: 25px;
  }
  .modal.show .modal-dialog {
    transform: none;
    margin: 0px auto;
    max-width: 350px;
  }
}
@media (max-width: 414px) {
  .product-wrapper .tabs-wrapper .nav-tabs .nav-link {
    padding: 10px 9px;
  }
  .product-wrapper .product-quality-btn {
    display: flex;
    align-items: baseline;
    margin: 22px 0px;
    flex-direction: column;
  }
  .product-wrapper .product-quality-btn .btn-wrapper {
    margin: 12px 0px;
  }
  .product-wrapper .pro-slider-img img {
    width: 92px;
  }
}
@media (max-width: 375px) {
  .product_page .product_page-heading-img-wraper img {
    height: 335px;
    max-width: 98%;
  }
  .product-wrapper .pro-slider-img img {
    width: 78px;
    height: 65px;
  }
  .product-wrapper .tabs-wrapper .nav-tabs .nav-link {
    padding: 10px 11px;
    font-size: 14px;
  }
  .product-wrapper .tabs-wrapper .nav-tabs .nav-link.active {
    font-size: 14px;
  }
}
