.cart-wrapper {
  background: url("../img/merch-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 200px;
  padding-bottom: 50px;
  margin-top: -231px;
}

.cart-wrapper .cart-wrappers {
  background: #2b2624;
  padding-top: 185px;
  border: 4px solid #f6ae00;
  outline: 9px solid #2b2624;
  padding-bottom: 20px;
  margin-top: -135px;
}
.cart-wrapper .cart-content-wrapper {
  text-align: center;
  position: relative;
  z-index: 2;
}
.cart-wrapper .cart-content-wrapper .cart-conetent-wrapper {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
}
.cart-wrapper .cart-content-wrapper .cart-conetent-wrapper h3 {
  font-size: 52px;
  color: #fff;
  text-shadow: -6px 3px #1e1b1a;
  text-transform: uppercase;
}
.cart-wrappers .product-card-wrapper {
  display: flex;
}
.cart-wrappers .product-card-wrapper .product-close {
  flex: 0 0 5%;
}
.cart-wrappers .product-card-wrapper .product-img-wrapper {
  flex: 0 0 40%;
  margin: 25px 0px;
}
.cart-wrappers .product-card-wrapper .product-content-wrapper {
  flex: 0 0 55%;
  margin: 25px 0px;
  align-self: center;
}
.cart-wrappers .product-card-wrapper {
  border: 3px solid #f6ae00;
  background: #000000;
}
.cart-wrappers .product-card-wrapper .product-close button {
  background: #f6ae00;
  border: 0px;
}
.cart-wrappers .product-card-wrapper .product-img-wrapper figure {
  margin: 0px 10px 0px 0px;
}
.cart-wrappers .product-card-wrapper .product-content-wrapper h4 {
  font-family: "Agency FB";
  font-size: 22px;
  color: #fff;
  font-weight: 700;
}
.cart-wrappers .product-card-wrapper .product-content-wrapper h5 {
  color: #f6ae00;
  font-size: 18px;
  font-family: "Khand";
  font-weight: 300;
}
.cart-wrappers .product-card-wrapper .product-content-wrapper p {
  color: #ffffff;
  font-size: 16px;
  font-family: "Khand";
}
.cart-wrappers .product-card-wrapper .product-content-wrapper h6 {
  color: #ffffff;
  font-family: "Khand";
  font-size: 22px;
  font-weight: 600;
}
.cart-wrappers .product-card-wrapper .product-content-wrapper h6 span {
  color: #f6ae00;
}
.cart-wrappers .product-card-wrapper {
  border: 3px solid #f6ae00;
  background: #000000;
  margin: 10px 17px;
}
.cart-wrapper .order-summary-wrapper {
  background: #000;
  padding: 27px;
  border: 4px solid #f6ae00;
  margin-right: 31px;
  margin-top: 10px;
}
.cart-wrapper .order-summary-wrapper h4 {
  font-family: "Agency FB";
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 29px;
  text-transform: uppercase;
}
.cart-wrapper .order-summary-wrapper h5 span {
  font-weight: 400;
}
.cart-wrapper .order-summary-wrapper h5 {
  font-family: "Khand";
  font-size: 25px;
  color: #fff;
  margin-bottom: 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.cart-wrapper .order-summary-wrapper p {
  color: #fff;
  font-weight: 300;
  font-size: 16px;
}
.promo-code {
  margin-top: 0px !important;
  border-top: 0px !important;
  padding: 46px 20px !important;
}
.promo-code input.form-control {
  height: 52px;
  border-radius: 0px;
}
.promo-code button {
  border: 4px solid #f6ae00;
  border-radius: 0px;
  height: 52px;
  color: #ffff;
  font-size: 16px;
  margin-left: 16px;
  padding: 0px 21px;
  font-family: "Poppins";
  font-weight: 700;
}
.promo-code button img {
  margin-right: 11px;
}
.cart-wrappers .btn-wrapper button.btn {
  border: 3px solid #f6ae00;
  border-radius: 0px;
  background: #000;
  color: #fff;
  font-family: "Poppins";
  font-size: 16px;
  height: 53px;
  width: 202px;
  margin: 0px 17px;
  font-weight: 700;
}
.cart-wrappers .btn-wrapper button.btn img {
  height: 27px;
  margin-right: 10px;
}
.cart-wrappers .btn-wrapper {
  margin-top: 29px;
  margin-bottom: 49px;
}
.promo-code h5 {
  font-family: "Montserrat" !important;
  font-size: 20px !important;
}
.promo-code p {
  font-size: 15px !important;
  font-family: "Montserrat";
}
@media (max-width: 1100px) {
  .cart-wrappers .product-card-wrapper .product-content-wrapper p {
    font-size: 14px;
  }
  .promo-code {
    margin-top: 0px !important;
    border-top: 0px !important;
    padding: 20px 20px !important;
  }
  .cart-wrapper .order-summary-wrapper h4 {
    font-size: 24px;
    margin-bottom: 12px;
  }
  .cart-wrapper .order-summary-wrapper h5 {
    font-size: 21px;
    margin-bottom: 10px;
  }
  .promo-code button {
    font-size: 12px;
    padding: 0px 6px;
  }
}
@media (max-width: 480px) {
  .cart-wrapper .order-summary-wrapper {
    margin: 0 10px;
  }
  .cart-wrapper .cart-content-wrapper .cart-conetent-wrapper h3 {
    font-size: 41px;
  }
  .cart-wrapper .cart-content-wrapper .cart-heading-img-wraper img {
    height: 317px;
  }
  .cart-wrappers .btn-wrapper button.btn {
    width: 185px;
  }
  .cart-wrapper .cart-content-wrapper {
    z-index: 0;
  }
}
@media (max-width: 375px) {
  .cart-wrappers .product-card-wrapper {
    border: 3px solid #f6ae00;
    background: #000000;
    margin: 10px 17px;
    flex-direction: column;
  }
  .cart-wrappers .product-card-wrapper .product-img-wrapper {
    flex: 0 0 40%;
    margin: 25px 0px;
    text-align: center;
  }
  .cart-wrappers .product-card-wrapper .product-content-wrapper {
    flex: 0 0 55%;
    margin: 25px 0px;
    align-self: center;
    padding: 24px;
  }
  .cart-wrappers .btn-wrapper {
    margin-top: 29px;
    margin-bottom: 49px;
    text-align: center;
  }
  .cart-wrappers .btn-wrapper button.btn {
    width: 89%;
    margin: 7px 0px;
  }
}
@media (max-width: 360px) {
  .cart-wrapper .cart-content-wrapper .cart-heading-img-wraper img {
    height: 226px;
  }
  .cart-wrapper .cart-content-wrapper .cart-conetent-wrapper h3 {
    font-size: 28px;
  }
}
