.sec-about {
	background-image: url("../img/field-trip-bg.png");
	background-repeat: no-repeat;
	background-size: cover;
	padding-top: 50px;
	margin-top: -136px;
}

.sec-about {
	background-attachment: unset;
}

.about-page-sec .about-card-wrapper {
	position: relative;
}

.swal-overlay .swal-button,
.swal-overlay .swal-button:hover {
	background-color: #f6ae00;
	box-shadow: none;
	border: 0px;
}

.about-page-sec .about-content-wrapper {
	position: absolute;
	top: 32%;
	left: 0;
	right: 0;
	text-align: center;
	width: 81%;
	margin: 0 auto;
}

.about-page-sec .about-card-wrapper {
	position: relative;
}

.about-page-sec .about-content-wrapper h3 {
	color: #fff;
	font-size: 50px;
	text-shadow: -6px 3px #1e1b1a;
}

.about-page-sec .about-content-wrapper p {
	color: #fff;
	font-size: 18px;
}

.about-card-wrapper .newsletter-wrapper {
	width: 71%;
	margin: 0px auto;
}

.about-card-wrapper .newsletter-wrapper .input-group {
	background: #000000;
	padding: 5px;
	border: 3px solid #f6ae00;
	align-items: center;
	padding-left: 1rem;
}

.about-card-wrapper .newsletter-wrapper .input-group input {
	background: transparent;
	border: 0px;
	font-family: "Poppins";
	color: #fff;
	height: 42px;
	border-bottom: 1px solid #f3ac20;
	border-radius: 0;
	padding: 0.75rem;
	margin: 0.75rem 0;
}

.about-card-wrapper input[type=number]::-webkit-inner-spin-button, 
.about-card-wrapper input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; 
}

.about-card-wrapper .newsletter-wrapper .input-group input:focus {
	outline: none;
	box-shadow: none;
}

.about-card-wrapper .newsletter-wrapper .input-group input::placeholder {
	color: #fff;
}

.about-card-wrapper .newsletter-wrapper .input-group button {
	height: 50px;
	border: 3px solid #f6ae00;
	border-radius: 0px;
	color: #fff;
	padding: 0px;
	width: 164px;
	font-family: "Poppins";
	font-weight: 700;
	font-size: 14px;
	margin-top: 0rem;
	margin-bottom: 0.35rem;
}

.about-card-wrapper .newsletter-wrapper .input-group button img {
	margin-right: 11px;
}

.follow-wrapper {
	padding: 150px 0px;
}

.follow-wrapper ul {
	padding: 0px;
	display: flex;
	list-style: none;
	justify-content: center;
}

.follow-wrapper ul li {
	margin: 0px 10px;
}

.follow-wrapper h1 {
	text-align: center;
	color: #fff;
	font-size: 60px;
	margin-bottom: 32px;
	text-shadow: -6px 3px #0c0b0b75;
}

.sec-about p {
	font-size: 30px !important;
	text-shadow: none !important;
}

.sec-about .about-card-wrapper {
	animation: none;
	margin-top: 105px;
}

.sec-about .about-card-wrapper .newsletter-wrapper .input-group button img {
	display: unset;
}

.about-card-wrapper h3.COMING {
	font-size: 69px;
	line-height: 1;
}

.sec-about .about-card-wrapper h3 {
	margin: 0px;
}

.sec-about .about-content-wrapper p {
	text-shadow: -2px 1px #000 !important;
	margin-bottom: 10px;
	font-size: 25px !important;
	margin-top: 6px;
}

@media (max-width: 1100px) {
	.about-page-sec .about-content-wrapper h3 {
		font-size: 43px;
	}

	.sec-about .about-content-wrapper p {
		font-size: 19px !important;
	}
}

@media (max-width: 480px) {
	.sec-about .about-card-wrapper .about-img-wrapper img {
		height: 409px;
	}

	.sec-about .about-content-wrapper h3 {
		font-size: 23px !important;
		text-shadow: none;
	}

	.sec-about .about-content-wrapper p {
		font-size: 15px !important;
	}

	.about-page-sec .about-content-wrapper {
		width: 100%;
	}

	.about-card-wrapper .newsletter-wrapper .input-group button {
		width: 139px;
		font-size: 12px;
	}

	.about-card-wrapper .newsletter-wrapper .input-group input::placeholder {
		font-size: 10px;
	}

	.follow-wrapper h1 {
		text-align: center;
		color: #fff;
		font-size: 39px;
		margin-bottom: 32px;
		text-shadow: -6px 3px #0c0b0b75;
	}

	.follow-wrapper ul li img {
		height: 37px;
	}

	.sec-about {
		margin-top: -163px;
	}

	.about-card-wrapper .newsletter-wrapper .input-group button {
		width: 217px;
	}

	.about-card-wrapper .newsletter-wrapper .input-group {
		background: #000000;
		padding: 5px;
		border: 3px solid #f6ae00;
		align-items: center;
		flex-direction: column;
	}

	.about-card-wrapper .newsletter-wrapper .input-group input {
		width: 100%;
	}

	.sec-about .about-card-wrapper .about-img-wrapper img {
		height: 458px;
	}

	.sec-about .about-content-wrapper p {
		font-size: 13px !important;
		width: 90%;
		margin: 10px auto;
	}
}

@media only screen and (max-device-height: 926px) and (orientation: landscape) {
	.about-card-wrapper .newsletter-wrapper .input-group button {
		height: 42px;
	}

	.about-page-sec .about-content-wrapper h3 {
		font-size: 31px;
	}

	.sec-about .about-content-wrapper p {
		margin-bottom: 0px;
		margin-top: 6px;
	}

	.about-card-wrapper .newsletter-wrapper .input-group input {
		height: 31px;
	}
}

@media only screen and (max-device-height: 667px) and (orientation: landscape) {
	.about-card-wrapper .newsletter-wrapper .input-group {
		flex-direction: row;
	}

	/* .about-card-wrapper .newsletter-wrapper .input-group input {
    width: 100%;
  } */
}