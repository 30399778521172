.creator-sec {
  background-image: url("../img/creator-sec-bg.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  padding-top: 100px;
  margin-top: -135px;
}
.creator-sec .creator-video-card-wrapper {
  background: #2b2624;
  border: 5px solid #f6ae00;
  outline: 9px solid #2b2624;
  text-align: center;
  padding-top: 165px;
  margin-top: -190px;
}
.creator-sec .creator-video-card-wrapper .video-wrapper {
  padding: 40px 0px;
}

.creator-sec .creator-sec-wrapper {
  background: #2b2624;
  padding: 23px;
  border: 5px solid #f6ae00;
  outline: 10px solid #2b2624;
  position: relative;
  margin-top: -134px;
  z-index: 1;
}
.creator-sec .creator-sec-wrapper figure {
  margin: 0;
}
.creator-sec-wrapper img.video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.creator-sec .creator-content-wrapper {
  text-align: center;
  position: relative;
  z-index: 2;
}
.creator-sec .creator-content-wrapper .creator-conetent-wrapper {
  position: absolute;
  top: 54%;
  left: 0;
  right: 0;
}
.creator-sec .creator-content-wrapper .creator-conetent-wrapper h3 {
  font-size: 52px;
  color: #fff;
  text-shadow: -6px 3px #1e1b1a;
}
.creator-sec .creator-content-wrapper .creator-conetent-wrapper h4 {
  font-size: 23px;
  color: #fff;
  text-shadow: -4px 3px #1e1b1a;
  text-transform: uppercase;
}
.creator-content-wrapper {
  animation: swing ease-in-out 6s infinite alternate;
  transform-origin: center -20px;
  /* box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5); */
}
.creator-content-wrapper img {
  /* border: 5px solid #f8f8f8; */
  display: block;
  margin: 0px auto;
}
.creator-content-wrapper:after {
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  /* border: 1px solid #999; */
  top: -10px;
  left: 50%;
  z-index: 0;
  border-bottom: none;
  border-right: none;
  transform: rotate(45deg);
}
/* nail */
.creator-content-wrapper:before {
  content: "";
  position: absolute;
  width: 5px;
  height: 5px;
  top: -14px;
  left: 54%;
  z-index: 5;
  border-radius: 50% 50%;
  /* background: #000; */
}
@media (max-width:1440px){
  .creator-content-wrapper{
    animation: none;
  }
}
@media (max-width: 1280px) {
  .creator-content-wrapper img {
    height: 400px;
  }
  .creator-sec .creator-content-wrapper .creator-conetent-wrapper h3 {
    font-size: 45px;
  }
  .creator-sec .creator-content-wrapper .creator-conetent-wrapper h4 {
    font-size: 20px;
  }
}
@media (max-width: 800px) {
}
.creator-sec .creator-content-wrapper {
  text-align: center;
  position: relative;
  z-index: 0;
}
@media (max-width: 480px) {
  .creator-content-wrapper img {
    height: 277px;
  }
  .creator-sec .creator-content-wrapper {
    animation: none;
    z-index: 0;
  }
  .creator-sec .creator-content-wrapper .creator-conetent-wrapper h3 {
    font-size: 27px;
  }
  .creator-sec .creator-content-wrapper .creator-conetent-wrapper h4 {
    font-size: 14px;
    text-shadow: none;
  }
  .creator-sec .creator-video-card-wrapper {
    padding-top: 65px;
    margin-top: -90px;
  }
  .creator-video-card-wrapper iframe {
    width: 96%;
  }
  .creator-sec {
    margin-top: -159px;
  }
}
